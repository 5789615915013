import axios from "axios";
import getEnv from "@/utils/env";

const ADJUSTMENT_APPLICATION_URL = getEnv("VUE_APP_ADJUSTMENT_APPLICATION_URL");

export function listAdjustApplications(params) {
  const queryString = Object.keys(params)
    .map((key) => key + "=" + params[key])
    .join("&");

  return axios.get(`${ADJUSTMENT_APPLICATION_URL}?${queryString}`);
}

export function getAdjustApplication(applicationId) {
  return axios.get(`${ADJUSTMENT_APPLICATION_URL}/${applicationId}`);
}

export function updateAdjustApplication(applicationId, application) {
  return axios.put(`${ADJUSTMENT_APPLICATION_URL}/${applicationId}`, application);
}

export function getAdjustApplicationEvents(applicationId) {
  return axios.get(`${ADJUSTMENT_APPLICATION_URL}/${applicationId}/events`);
}

export function getOtherActiveAjustApplications(applicationId, customerId) {
  return axios.get(`${ADJUSTMENT_APPLICATION_URL}/${applicationId}/other-active-apps/${customerId}`);
}

export function setCreditStatus() {
  return axios.get(`${ADJUSTMENT_APPLICATION_URL}/set_credit_status`);
}

export function setBillCycle() {
  return axios.get(`${ADJUSTMENT_APPLICATION_URL}/set_bill_cycle`);
}

export function postAdjustApplicationEvents(applicationId, description, email_body) {
  return axios.post(`${ADJUSTMENT_APPLICATION_URL}/${applicationId}/events`, {description, email_body});
}

export function postAdjustApplicationEmail(applicationId, subject, text_body, html_body, current_endpoint) {
  return axios.post(`${ADJUSTMENT_APPLICATION_URL}/${applicationId}/send_email`, {subject, text_body, html_body, current_endpoint});
}

export function getAdjustApplicationEmailPreview(applicationId, template_name) {
  return axios.post(`${ADJUSTMENT_APPLICATION_URL}/${applicationId}/email_preview`, {template_name});
}

export function putAdjustApplicationState(applicationId, state, log_event=true, reason_for_state=null, wake_up_date=null) {
  return axios.put(`${ADJUSTMENT_APPLICATION_URL}/${applicationId}/state`, {state, log_event, reason_for_state, wake_up_date});
}

export function putAdjustApplicationStateBatch(application_ids, state, log_event=true, reason_for_state=null, wake_up_date=null) {
  return axios.put(`${ADJUSTMENT_APPLICATION_URL}/batch_state_update`, {application_ids, state, log_event, reason_for_state, wake_up_date});
}
