/* Probably shouldn't be a Mixin, but need access to Vue context and store.
 * No time to look into non-mixin solutions at this time.
 */
import {
  postAdjustApplicationEmail,
  postAdjustApplicationEvents,
  putAdjustApplicationState,
} from "@/apis/adjustmentApplications";

export default {
  props: { },

  methods: {
    updateState(applicationId, state, logEvent=false, reasonForState=null, wakeUpDate=null) {

      putAdjustApplicationState(applicationId, state, logEvent, reasonForState, wakeUpDate)
        .then(({ data }) => {
          this.$store.commit('ui/setModalData', data);
        })
        .catch((err) => {
          this.notify(
            "danger",
            "Adjustment Application state could not be updated",
            err.response.data.message
          );
          console.log(err)
        });
    },

    logEvent(applicationId, description, email_body) {
      console.log(`Logging event:\napplication_id: ${applicationId}\ndescription: ${description}\nemail_body: ${email_body}`);

      postAdjustApplicationEvents(
        applicationId,
        description,
        email_body,
      )
        .then(() => {})
        .catch((err) => {
          this.$store.dispatch("ui/openNotesModal", {modalId: 'manual-event-log-modal', ui: this});
          console.log(err)
        });
    },

    sendEmail(applicationId, email_body) {
      console.log('Sending email')

      postAdjustApplicationEmail(
        applicationId,
        "Bill Adjustment Decision",
        email_body,
        email_body.split('\n').join('<br>'),
        window.location.href.replace(/\?(.*)/,''),
      )
        .then(() => {})
        .catch((err) => {
          this.notify(
            "danger",
            "Failed to send email to customer",
            err.response.data.message
          );
          console.log(err);
        });
    },

    downloadCSV(data, file_name, notify=true) {
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.setAttribute("href", url);
      link.setAttribute("download", file_name);
      document.body.appendChild(link); // Required for FF
      if (notify) {
        this.notify("success", "Export successful", "Downloading CSV...");
      }
      link.click();
    },
  },
}
